.change-tooltip-list-item-title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

.change-tooltip-list-item {
  font-size: 14px;
  line-height: 22px;
  display: block;
  margin-bottom: 4px
}