.max-fumo-modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  margin-top: -4px;
  margin-bottom: 16px;
}

.max-fumo-all-check {
  text-align: right;
  padding-right: 21px;
}

.max-fumo-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.max-fumo-check-col {
  text-align: center;
  margin-bottom: 12px;
}

.max-fumo-content {
  /* width: 50%; */
  margin: 0 32px;
}

.max-fumo-title {
  font-size: 16px;
  color: var(--ant-primary-color);
  line-height: 22px;
  margin-bottom: 8px;
}

.max-fumo-dps {
  font-size: 32px;
  color: var(--ant-primary-color);
  line-height: 32px;
  margin-bottom: 8px;
  color: #F34242;
  font-weight: 600;
}

.max-fumo-dps-icon {
  font-size: 24px;
  margin-left: 8px;
}

.max-fumo-item {
  font-size: 16px;
  color: #333;
}

.max-fumo-item + .max-fumo-item {
  margin-top: 4px;
}


.max-fumo-label {
  margin-right: 12px;
  font-weight: 400;
}

.max-fumo-value {
  font-size: 16px;
  font-weight: 600;
}