.character-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.character-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: var(--ant-primary-color);
}

.character-title-wrapper .ant-checkbox {
  margin-left: 8px;
}

.character-title-wrapper .ant-checkbox+span {
  padding-inline-end: 0;
}

.character-item-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.character-item {
  display: flex;
  margin-bottom: 8px;
  height: 32px;
  width: 100%;
}

.character-item.character-item-harf {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .character-item.character-item-harf {
    width: 48%;
  }
}

.character-label {
  width: 36px;
  text-align: left;
  line-height: 32px;
  font-size: 14px;
  margin: 0 4px 0 0;
}

.character-content {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  overflow: hidden;
  line-height: 32px;
  border-radius: 2px;
  padding: 0 11px;
  border: 1px solid #d9d9d9;
}

.character-content-normal {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
}

.character-content-max {
  font-size: 14px;
  font-weight: 600;
  color: #666
}

.character-max-title-tip {
  font-size: 14px;
  color: #666;
  margin-left: 8px;
}