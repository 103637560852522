.title-tip {
  /* width: 348px; */
  position: fixed;
  bottom: 0;
  left: 24px;
  color: #fff;
  margin: 0;
  line-height: 36px;
  font-size: 14px;
  display: flex;
  /* justify-content: space-between; */
  text-shadow: 1px 1px rgba(0, 0, 0, 0.12);
}

.service-tip {
  margin-left: 0px;
}