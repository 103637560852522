.common-set {
  margin-bottom: 12px;
}

.common-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
  color:var(--ant-primary-color);
  display: flex;
  justify-content: space-between;
}

.common-title-project {
  margin-right: 8px;
}

.common-title-zengyi {
  color: #ED4F00;
  border-color: #ED4F00;
}

.common-title-zengyi:hover,
.common-title-zengyi:focus {
  color: #fa7528;
  border-color: #fa7528;
}

.common-item {
  display: flex;
  margin-bottom: 8px;
  height: 32px;
  justify-content: space-between;
}

.common-item-sub {
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  height: 32px;
  justify-content: space-between;
}

.common-item>.ant-btn{
  width:calc(33% - 4px);
}

.common-label {
  width: 36px;
  text-align: left;
  line-height: 32px;;
  font-size: 14px;
  margin: 0 4px 0 0;
}

.common-content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow: hidden;
}
