.basic-set {
  display: flex;
  /* width: 328px; */
  border-radius: 4px;
  background:#fff;
  height: calc(100vh - 72px);
  overflow-y: auto;
  padding: 16px 24px;
  position: relative;
}

.basic-set-info {
  width: 300px;
  padding-bottom: 90px;
}

.basic-set .ant-divider {
  margin: 12px 0;
}

.basic-set-zengyi {
  width: 0;
  height: 0;
  opacity: 0;
  transition: .2s;
  overflow: hidden;
  /* overflow-y: auto; */
}

.basic-set-zengyi.basic-set-zengyi-visible {
  width: 420px;
  height: auto;
  overflow: unset;
  opacity: 1;
}

.basic-set-zengyi.basic-set-zengyi-visible.onGuide {
  overflow: hidden;
}
