html,
body,
#__next {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  font-size: 12px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

ul {
  list-style: none;
}

input,
body {
  font-family:
    'Pingfang SC',
    'Microsoft YaHei',
    STHeiti,
    Verdana,
    Arial,
    Tahoma,
    sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:lang(zh) body {
  font-family:
    'Pingfang SC',
    'Microsoft YaHei',
    STHeiti,
    Verdana,
    Arial,
    Tahoma,
    sans-serif;
}

:lang(en) body {
  font-family:
    'Pingfang SC',
    'Microsoft YaHei',
    STHeiti,
    Verdana,
    Arial,
    Tahoma,
    sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:focus {
  outline: 0;
}

p,
ul {
  margin-bottom: 0;
}

.iconfont {
  font-size: 16px;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.clearfix {
  zoom: 1;
}

.clearfix::before {
  display: table;
  content: ' ';
}

.clearfix::after {
  display: table;
  clear: both;
  content: ' ';
}

/* 项目定制 */

.container {
  padding: 20px;
}

.content {
  margin-bottom: 20px;
  background: #fff;
}

.dps-up-color {
  color: hsl(140, 60%, 40%);
}

.dps-low-color {
  color: #F34242;
}