.jinglian-select.ant-select.ant-select-in-form-item {
  width: 54px;
}

.jinglian-not-max {
  color: #F34242;
}

.jinglian-max {
  color: hsl(140, 60%, 40%);
  font-weight: 500;
}