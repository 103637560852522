.cycle-select-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cycle-select-provider {
  color: rgb(0, 128, 128);
  font-weight: 500;
}

.cycle-select-item-tag {
  color: #f5222d;
  margin-left: 8px;
}

.cycle-select-item-tag-purple {
  color: #531dab;
}

.cycle-select-item-tag-orange {
  color: #fa7528;
}

.cycle-select-item-tag-green {
  color: #2AC88F;
}

.cycle-select-popover-content {
  max-width: 300px
}