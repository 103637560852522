.log-wrap {
  width: 348px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 24px;
  color: #fff;
  margin: 0;
  line-height: 36px;
  font-size: 14px;
}

.log {
  cursor: pointer;
  transition: .2s;
}

.log-wrap .log:hover {
  color: #ddd;
}

.log-modal .ant-modal-body{
  min-height: 400px;
}

.log-modal .ant-modal-body,
.new-log-modal .ant-modal-body{
  padding-top: 16px;
}

.log-line .ant-timeline-item-content{
  top: -6px;
  display: flex;
  justify-content: space-between;
}

.log-content-text {
  flex: 1;
}

.log-right {
  line-height: 20px;
  font-size: 14px;
  width: 150px;;
  text-align: right;
}

.log-version {
  min-width: 80px;
  text-align: right;
  font-weight: 600;
  color: var(--ant-primary-color);
}

.log-date {
  color: #666;
}

.question-link {
  line-height: 32px;
  font-size: 24px;
  text-align: left;
  padding: 24px 0 0;
  margin-bottom: 0;
}

.question-link-img {
  margin: 0 -16px;
}

.question-link-img-wrap {
  display: flex;
  align-items: flex-end;
}

.question-link-img-text {
  margin-bottom: 16px;
  margin-left: 16px;
}