.zhuangbei-input-set-modal .ant-form-item {
  margin-bottom: 12px;
}

.zhuangbei-input-set-modal .ant-modal-body {
  padding-top: 16px;
}

.zhuangbei-input-set-modal .ant-form-item-label {
  display: inline-block;
  text-align: right;
}

.zhuangbei-input-set-modal .ant-modal-content {
  min-width: 1178px;
  min-height: 116px;
  max-height: calc(100vh - 32px);
  overflow-y: auto;
}

.zhuangbei-input-set-modal .ant-form-item.jisuan {
  text-align: right;
  margin-bottom: 0;
}

.zhuangbei-select {
  display: flex;
}

.zhuangbei-form-header {
  display: flex;
  margin-bottom: 8px;
  height: 26px;
}

.zhuangbei-form-title {
  font-size: 16px;
  color: var(--ant-primary-color);
  font-weight: 600;
  margin: 0;
}

.zhuangbei-form-left-1 {
  width: 384px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.zhuangbei-diff-btn {
  margin-left: 12px;
  font-weight: 400;
}

.zhuangbei-diff-tip {
  font-size: 14px;
  color: #666;
  margin-left: 8px;
}

.zhuangbei-form-left-2 {
  width: 122px;
}

.zhuangbei-form-left-3 {
  width: 64px;
}

.zhuangbei-form-left-4 {
  width: 206px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.zhuangbei-form-left-5 {
  width: 90px;
}

.zhuangbei-form-left-6 {
  width: 86px;
  padding-left: 8px;
}

.zhuangbei-form-set-btn {
  margin-left: 12px;
}

.zhuangbei-input-set-modal-form {
  display: flex;
}

.zhuangbei-input-set-modal-form-left {
  width: 850px;
}

.zhuangbei-set-dafumo-wrapper {
  width: 86px;
  margin-left: -72px;
}

.zhuangbei-input-set-modal-form-right {
  width: calc(100% - 784px - 80px);
  padding-left: 10px;
  position: relative;
}

.zhuangbei-input-set-modal-form-luling {
  position: absolute;
  top: -37px;
  right: 0px;
}

.dps-diff-item {
  margin-bottom: 16px;
}

.dps-diff-item+.dps-diff-item {
  margin: 0;
}

/* .dps-diff {
  padding-top: 16px;
} */

.dps-diff-title,
.time-label-title,
.zhuangbei-zengyi-title {
  font-size: 14px;
  color: var(--ant-primary-color);
  font-weight: 600;
  margin-bottom: 6px;
}

.dps-diff-dps {
  font-size: 28px;
  margin-bottom: 0;
  padding: 8px 0;
  line-height: 28px;
  color: #666;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.dps-diff-error-text {
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}

.time-label {
  border: 1px solid #d9d9d9;
  padding: 6px 0;
  display: flex;
  justify-content: space-around;
}

.zhuangbei-input-peizhuangtuijian {
  font-weight: 400;
  font-size: 14px;
  margin-right: 20px;
}

.zhuangbei-input-set-modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -4px;
}

.zhuangbei-input-set-modal-title-operate {
  margin-right: 24px;
}

.dps-diff-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dps-diff-percent {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 6px;
}