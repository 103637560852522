.income-wrap {
  width: 100%;
  height: calc(100vh - 350px);
  position: relative;
}

.income-custom,.income-chart {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: calc(100vh - 350px);
}

.income-custom {
  z-index: 1;
}

.income-chart {
  z-index: 0
}

.income-chart-tooltip .ant-tooltip-inner {
  width: 320px;
}

.income-chart-title {
  display: inline-block;
  text-align: center;
  line-height: 20px;
  margin-right: 16px;
  font-size: 16px;
}

.income-chart-title .anticon {
  vertical-align: -1;
  margin-left: 8px;
  font-size: 14px;
  color: #ccc;
}

.income-type-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.income-type-select-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #fff;
  color: var(--ant-primary-color);
}

.income-type-select-radio .ant-radio-button-wrapper {
  color: #fff;
  background: none;
}