.layout {
  width: 100%;
  position: relative;
  height: 100vh;
  /* background: var(--ant-primary-color); */
  overflow: hidden;
}

.layout-wrapper {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  margin: auto;
  display: flex;
  padding: 36px 24px;
  flex-direction: row;
  box-sizing: border-box;
  /* background: #08122b; */
}

.layout-bg {
  position: absolute;
  display: block;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 1;
  margin: auto;
  width: 100%;
  object-fit: cover;
  /* margin-right:-5%; */
}

.layout-bg-color {
  position: absolute;
  display: block;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  margin: auto;
}