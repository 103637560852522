.wufeng-daoru-wrap {
  padding-top: 16px;
  padding-bottom: 12px;
}

.wufeng-daoru-form-content {
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.wufeng-daoru-form-item {
  flex: 1;
  margin-right: 12px;
}

.wufeng-daru-res-text {
  font-size: 14px;
}

.wufeng-daru-res-text + .wufeng-daru-res-text {
  margin-top: 8px;
}

.wufeng-daru-res-warp {
  padding: 12px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.wufeng-daru-res-text-count {
  font-weight: 500;
  padding: 0 4px;
}

.wufeng-daru-res-success {
  color: #0ab559;
}

.wufeng-daru-res-error {
  color: #F34242;
}