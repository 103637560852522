.dps {
  width: calc(100vw - 348px - 24px - 24px);
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 24px;
  border-radius: 4px;
  transition: width .2s;
}

.dps.dps-zengyi-visible {
  width: calc(100vw - 300px - 420px - 48px - 48px);
}

.dps .ant-divider {
  margin: 16px 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.dps-title {
  font-size: 16px;
  color: #fff;
  margin: 0;
}

.dps-number-count-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.dps-number-count {
  padding: 16px 0 8px;
  display: flex;
  text-align: left;
  align-items: flex-end;
}

.dps-number-count-time {
  padding: 16px 0 12px;
  font-size: 16px;
  font-weight: 600;
}

.dps-number-count-time-label {
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
}

.dps-number-tip {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 8px;
}

.dps-income-tip {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.dps-number-count-text {
  font-size: 64px;
  line-height: 1;
  font-weight: 600;
}

.dps-number-count-skill {
  color: #ED4F00;
  margin-left: 16px;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
}

.dps-number-count-skill-btn {
  font-weight: 600;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.12);
  margin-bottom: 8px;
  /* font-size: 20px; */
}

.dps-number-count-skill:hover {
  color: #fa7528;
}

.dps-res-chart {
  display: flex;
}

.dps-res-chart.dps-chart-show-skill .dps-res-chart-wrap{
  width: 50%
}

.dps-res-chart-wrap {
  width: 100%;
}

.dps-number-count-no-result {
  font-size: 20px;
  /* text-align: center; */
  /* margin: 0 auto; */
}