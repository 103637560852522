.school-switch-modal-wrap .ant-modal-content{
  min-height: 248px;
}

.school-switch-wrap {
  display: flex;
  align-items: center;
}

.school-switch-title {
  font-size: 16px;
  margin: 0;
}

.school-switch-tabs {
  margin-left: 186px;
  margin-top: -4px;
}

.school-switch-modal {
  display: flex;
  flex-wrap: wrap;
  margin: 12px -8px 0px;
}

.school-switch-item {
  width: calc(100% /4 - 8px);
  margin: 4px;
  cursor: pointer;
  border-radius: 8px;
  transition: .2s;
  height: 48px;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}

.school-switch-item-content {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 8px 12px 8px 16px;
  display: flex;
  align-items: center;
  z-index: 211;
}

.school-switch-item-img-bg {
  position: absolute;
  right: -20px;
  top: 10px;
  display: block;
  width: 60px;
  height: 60px;
  opacity: 0;
  transition: .2s;
  z-index: 210;
}

.school-switch-item-bg {
  position: absolute;
  width: 100%;
  left: 0;
  height: 48px;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 209;
  opacity: 0;
  transition: .2s;
}

.school-switch-list-active {
  color: var(--ant-primary-color);
  font-weight: 500;
}

.school-switch-item:hover {
  color: #fff;
  font-weight: 500;
  background-color: var(--ant-primary-color-deprecated-bg);
}

.school-switch-item:hover .school-switch-item-img-bg {
  opacity: 0.2;
}

.school-switch-item:hover .school-switch-item-bg {
  opacity: 0.7;
}

.school-switch-item:hover .school-switch-item-text {
  color: #fff;
}

.school-switch-item-img {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}


.school-switch-list-item {
  display: flex;
  align-items: center;
  padding-right: 4px;
}

