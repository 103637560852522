.cache-wrapper {
  position: fixed;
  bottom: 0;
  right: 24px;
  margin: 0;
  line-height: 36px;
  font-size: 14px;
  text-align: right;
}

.cache-btn {
  transition: .2s;
  color: #fff;
  cursor: pointer;
  margin-left: 16px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
}

.cache-btn:hover {
  color: #ddd;
}