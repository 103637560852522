.wucaishi-wrap {
  width: 100%;
  position: relative;
}

.wucaishi-xuanze-wrap .wucaishi-xuanze {
  width: calc(100% - 70px);
}

.wucaishi-xuanze-wrap .wucaishi-xuanze-dengji {
  width: 70px;
}

.wucaishi-xuanze-wrap {
  display: flex;
}

.wucaishi-value {
  position: absolute;
  top: -35px;
  right: 0px;
  width: 196px;
  height: 26px;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  text-align: center;
}

.wucaishi-value-5 {
  color: #f90;
}

.wucaishi-value-6 {
  color: #ff2dff;
}