.zhuangbei-select {
  width: 300px;
}

.zhuangbei-miaoshu-label+.zhuangbei-miaoshu-label {
  margin-left: 4px;
}

.zhuangbei-select-item.ant-select-item {
  min-height: 24px;
}

.zhuangbei-select-item .ant-select-item-option-content,
.zhuangbei-select .ant-select-selector .ant-select-selection-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zhuangbei-select-item-wrap {
  display: flex;
  align-items: center;
}

.zhuangbei-select-img {
  width: 20px;
  border-radius: 4px;
  margin-right: 8px;
}

.zhuangbei-select-name {
  /* font-size: 16px; */
  font-weight: 600;
  margin-right: 8px;
  /* color: #1AA174; */
  color: var(--ant-primary-color);
}

.zhuangbei-select-name-cw {
  color: #f90;
}

.zhuangbei-select-shuoming {
  color: #666
}

.zhuangbei-miaoshu-label-jingjian {
  color: #F34242;
}

.zhuangbei-miaoshu-label-shilian {
  color: rgb(0, 128, 128);
}

.zhuangbei-miaoshu-label-pvx {
  color: #ff2dff;
}

.zhuangbei-select-level {
  color: #000000A6;
  /* line-height: 30px; */
}

.zhuangbei-diff {
  margin-right: 8px;
}

