
.equip-modal .ant-modal-header {
  padding: 0;
}

.equip-modal {
  position: relative;
}

.equip-modal-tabs {
  margin-top: -12px;
}

.equip-modal-old-dps {
  position: absolute;
  top: 12px;
  left: 520px;
  line-height: 14px;
  font-weight: 400;
  font-size: 14px;
  pointer-events: unset;
  border-radius: 4px;;
}
