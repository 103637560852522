.character-set {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 12px;
}

.character-set .character-set-btn,.character-set .ant-badge {
  width: calc(50% - 6px);
}

.character-set-in-btn {
  width: 100%;
}