.max-wucaishi-modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
}

.max-wucaishi-wrap {
  padding-top: 12px;
}

.max-wucaishi-wrap-has-max {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  font-weight: 500;
}

.max-wucaishi-wrap-content {
  display: flex;
  align-items: flex-end;
  /* justify-content: space-between; */
  margin-bottom: 12px;
}


.max-wucaishi-content + .max-wucaishi-content {
  margin-left: 24px;
}

.max-wucaishi-title {
  font-size: 12px;
  color: var(--ant-primary-color);
  line-height: 16px;
  margin-bottom: 4px;
}

.max-wucaishi-dps {
  font-size: 24px;
  color: var(--ant-primary-color);
  line-height: 24px;
  margin-bottom: 4px;
}

.max-wucaishi-dps-icon {
  font-size: 16px;
  margin-left: 4px;
}

.max-wucaishi-item {
  font-size: 16px;
  color: #333;
  line-height: 16px;
  margin-top: 8px;
}

.max-wucaishi-item + .max-wucaishi-item {
  margin-top: 4px;
}

.max-wucaishi-all-value {
  font-weight: 500;
  margin-right: 4px;
  color: var(--ant-primary-color);
}

.max-wucaishi-label {
  margin-right: 12px;
  font-weight: 400;
}

.max-wucaishi-value {
  font-size: 16px;
  font-weight: 500;
}

/* .max-wucaishi-table {
  height: calc(100vh - 400px);
} */