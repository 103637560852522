.basic-footer {
  display: flex;
  justify-content: space-between;
  border-radius: 4px 0 0 4px;
  background: #fff;
  padding: 0;
}

.basic-footer-btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
}