.zhuangbei-character-show {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 16px 12px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  margin-bottom: 12px;
  overflow: hidden;
  position: relative;
  transition: .2s;
}

.zhuangbei-character-show:hover {
  padding-top: 32px;
}
.zhuangbei-character-show:hover .zhuangbei-show-zengyi-tag{
  top: -1px;
}

.zhuangbei-character-show.zhuangbei-character-zengyi {
  border-color: var(--ant-primary-color)
}

.zhuangbei-show-zengyi-tag {
  position: absolute;
  top: -23px;
  left: 0;
  background-color: #fff;
  width: 264px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

.tag-active{
  position: absolute;
  z-index: 0;
  width: 88px;
  height: 24px;
  background-color: var(--ant-primary-color);
  left: 50%;
  transition: .2s;
}

.zhuangbei-character-zengyi .shouw-zengyi-charactor {
  color: #fff;
}

.zhuangbei-character-wuzengyi .tag-active {
  margin-left: -88px;
}

.zhuangbei-character-wuzengyi .hide-zengyi-charactor{
  color: #fff;
}

.show-zengyi-tag-item {
  padding: 0 8px;
  position: relative;
  z-index: 1;
  color: var(--ant-primary-color);
  transition: .2s;
  cursor: pointer;
  font-weight: 400;
}

.show-zengyi-tag-item:hover {
  font-weight: 500;
}

.zhuangbei-character-item {
  width: 50%;
  display: flex;
  height: 24px;
}

.zhuangbei-character-item-last {
  width: 100%;
  padding-right: 58px;
}

.zhuangbei-character-item:nth-child(-n+10) {
  margin-bottom: 8px;
}

.zhuangbei-character-label {
  width: 40px;
  text-align: left;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  /* color: var(--ant-primary-color); */
  margin: 0;
}

.zhuangbei-character-content {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  overflow: hidden;
  line-height: 24px;
  text-align: right;
}

.zhuangbei-character-content-normal {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
}

.zhuangbei-character-content-normal.zhuangbei-character-content-upper {
  color: hsl(140, 60%, 40%);
}

.zhuangbei-character-content-normal.zhuangbei-character-content-down {
  color: #F34242;
}

.zhuangbei-character-max-title-tip {
  font-size: 14px;
  color: #666;
  margin-left: 8px;
}