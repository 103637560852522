.account-daoru-input-wrap {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-daoru-input-wrap.no-padding {
  padding: 0;
}

.account-daoru-form-server {
  flex: 1;
  margin-right: 12px;
}

.account-daoru-form-content {
  margin-right: 12px;
}

.account-daoru-success {
  padding-top: 12px;
}

.account-daoru-success-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-daoru-text-wrap {
  height: 100px;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.account-daoru-success-info {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border:1px solid #d9d9d9;
  border-radius: 8px;
  padding: 12px 16px;
}

.account-daoru-success-tip{
  margin-bottom: 12px;
  color: #0ab559;
  font-weight: 500;
}

.account-daoru-success-title {
  flex: 1;
  font-weight: 600;
  font-size: 16px;
  color: var(--ant-primary-color);
  /* max-width: 134px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.account-daoru-success-avatar {
  display: block;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
  height: 40px;
  margin-right: 8px;
}

.account-daoru-text-content {
  flex: 1
}

.account-daoru-text-error {
  color: #F34242
}

.account-daoru-help-img {
  width: 100%;
}

.account-daoru-res-tips {
  padding-top: 12px;
}

.account-daoru-res-tips-title {
  font-size: 14px;
  font-weight: 500;
  color: #F34242;
  margin-bottom: 12px;
}

.account-daoru-res-tips-text {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
}

.account-daoru-res-tips-name {
  font-weight: 500;
  margin-bottom: 2px;
}

.account-daoru-success-name-error {
  color: #F34242
}