.xiangqian-select-wrap {
  display: flex;
  width: 280px;
  justify-content: flex-start;
}

.xiangqian-item {
  display: flex;
  align-items: center;
  width: 90px;
}

.xiangqian-item + .xiangqian-item{
  margin-left: 12px;
}

.xiangqian-label {
  display: inline-block;
  white-space: nowrap;
  margin-right: 8px;
}

.xiangqian-select.ant-select.ant-select-in-form-item {
  width: 52px;
}
.xiangqian-number-1,
.xiangqian-number-2,
.xiangqian-number-3,
.xiangqian-number-4,
.xiangqian-number-5 {
  color: #777;
}

.xiangqian-number-6 {
  color: #000;
}

.xiangqian-number-7 {
  color: #ff2dff;
}

.xiangqian-number-8 {
  color: #f90;
}