.attr-replace {
  /* padding-top: 24px; */
}

.attr-replace-btns {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.attr-replace .ant-col {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5)
}

.attr-replace-table {
  border-top: 1px solid rgba(255, 255, 255, 0.5)
}

.attr-replace-name {
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 16px;
  text-align: center;
}

.attr-replace-sider {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  height: calc((100vh - 450px) / 7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attr-replace-header-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.attr-replace-header {
  line-height: 48px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
}

.attr-replace-name-value {
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 20px;
  text-align: center;
}

.attr-replace-res {
  font-size: 16px;
  text-align: center;
  position: relative;
}

.attr-replace-res-text {
  text-align: center;
  font-weight: 400;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.8);
  z-index: 20;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.attr-replace-res-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  opacity: 0.95;
  z-index: 0;
}


.attr-value-up-1 {
  /* background-color: #3e8e47; */
  background-color: hsl(140, 60%, 40%);
}

.attr-value-up-2 {
  /* background-color: #22663a; */
  background-color: hsl(140, 60%, 30%);
}


.attr-value-max {
  background-color: hsl(140 60% 12% / 1);
  border: 2px solid rgba(255,255,255,0.9);
  font-weight: 700;
}


.attr-value-low-1 {
  background-color: hsl(0 60% 55% / 0.9);
}

.attr-value-low-2 {
  background-color: hsl(0 75% 40% / 0.9);
}

.attr-value-min {
  background-color: hsl(0 60% 15% / 0.9);;
  font-weight: 700;
}

.attr-repace-icon {
  color: #ffbc12;
  font-size: 14px;
}

.attr-repace-item-icon {
  position: absolute;
  z-index: 3;
  left: 4px;
  top: 4px;
}

.attr-replace-tip-item {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.attr-replace-tip-item + .attr-replace-tip-item {
  margin-top: 4px;
}

.attr-replace-tip-label {
  font-size: 14px;
  margin-right: 4px;
  font-weight: 400;
  color: #666;
}

.attr-replace-tip-label-up {
  font-weight: 500;
  color: #333;
}

.attr-replace-tip-num {
  color: #F34242;
  font-size: 14px;
  font-weight: 400;
}

.attr-replace-tip-num-up {
  color: hsl(140, 60%, 40%);
  font-weight: 500;
}